<template>
  <div class="container">
    <div class="title">所有分类</div>
    <div class="catgorys">
    <div class="catgory" v-for="(catgory,index) in catgorys" v-bind:key="index" @click="search(catgory)">
      {{catgory}}
    </div>
  </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      catgorys: [
        '玄幻·奇幻','都市·青春','修真·仙侠','历史·军事','言情·穿越','科幻·灵异','网游·竞技','台言·古言','其他类别',
        '侦探·推理','耽美·同人','影视文学','童话故事','外国文学','古典文学','武侠小说','传记纪实','当代现代','韩流青春'
      ]
    }
  },
  methods:{
    search(key) {
      console.log(key)
      this.$router.push({name: 'SearchResult', query:{cat: key}})
    }
  }
}
</script>
<style lang="less" scoped>
  .container{height: 100%;max-width: 800px;margin:0 auto;}
  .title{
    height: 40px;
    font-size:1.2rem;
    border-bottom: 1px solid #ccc;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 10px;
  }
  .catgorys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5px;
    .catgory{
      width: 33.333333%;
      height: 35px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:nth-child(3n+1){
        background-color: rgb(180, 175, 175);
      }
      &:nth-child(3n+2){
        background-color: rgb(183, 190, 188);
      }
      &:nth-child(3n+3){
        background-color: rgb(211, 218, 211);
      }
    }
  }
</style>
